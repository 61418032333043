import SuccessResponse, { ServerSuccessResponse } from "./SuccessResponse";

export default class FormSuccessResponse extends SuccessResponse<any> {
    private _isSubmitting = false;

    constructor() {
        super(false);
    }

    get isSubmitting() {
        return this._isSubmitting;
    }
    submit() {
        super.submit();
        this._isSubmitting = true;
    }
    setResults(results:ServerSuccessResponse<any>) {
        super.setResults(results);
        this._isSubmitting = false;
    }
    setRedirect() {
        super.setRedirect();
        this._isSubmitting = false;
    }
    setError(err:Error) {
        super.setError(err);
        this._isSubmitting = false;
    }
    setMessage(message:string) {
        super.setMessage(message);
        this._isSubmitting = false;
    }

}