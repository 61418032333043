import React, { createContext, useMemo, useState } from "react";
import { fetchWithData } from "../functions";
import FormSuccessResponse from "../Models/Responses/FormSuccessResponse";
import { FormValidation, ServerSuccessResponse } from "../Models/Responses/SuccessResponse";
import "./FormProvider.scss";

export interface FormProviderContext {
    response: FormSuccessResponse;
    validation: FormValidation | null;
    submitForm: () => void;
}

export const FormContext = createContext<FormProviderContext>({
    response: new FormSuccessResponse(),
    validation: null,
    submitForm: () => {},
});

interface FormProps extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
    data: Object;
    type: "POST" | "PUT";
    url: string;
    afterSuccess:(results:ServerSuccessResponse<any>) => void;
}

export default function FormProvider(props:FormProps) {
    const [response, setResponse] = useState(new FormSuccessResponse());
    const {url, type, data, afterSuccess, ...formProps} = props;

    const formContext:FormProviderContext = useMemo(() => {
        return {
            response:response,
            validation: (response.results && response.results.validation)?response.results.validation : null,
            submitForm: () => {
                if (!response.isSubmitting) {
                    const newResponse = new FormSuccessResponse();
                    newResponse.submit();
                    setResponse(newResponse);
                    (async () => {
                        const successResponse = await fetchWithData(type, url, response, data);
                        setResponse(successResponse);
                        if (successResponse.results && successResponse.results.success) {
                            afterSuccess(successResponse.results);
                        }
                    })();
                }
            },
        };
    }, [response, type, url, data, afterSuccess]);

    const validationArray:any[] = [];
    if (formContext.validation && formContext.validation["__all__"]) {
        formContext.validation["__all__"].forEach((item) => {
            validationArray.push(item.message);
        });
    }

    return (
        <FormContext.Provider value={formContext}>
            <form
                {...formProps}
                className={`form ${formProps.className?formProps.className:""}`}
                onSubmit={(event) => {
                    /**
                     * Prevent Default here keeps a nested form's submission
                     * from bubbling up to other forms.
                     */
                    event.preventDefault();
                    formContext.submitForm();
                }}
            >
                <div className="validation">{validationArray}</div>
                {props.children}
                <button className="hidden" type="submit">Submit</button>
            </form>
        </FormContext.Provider>
    );
}

export function useGetFormContext() {
    return React.useContext(FormContext);
}
