import React from "react";
import ButtonTypes from "./ButtonTypes";
import "./Buttons.scss";

type ButtonProps = {
    style?: React.CSSProperties;
    label: string;
    type:ButtonTypes;
    disabled?:boolean;
    clickEvent:(event:React.MouseEvent)=>void;
    buttonStyle?:React.CSSProperties;
};

export function Button(props: ButtonProps) {
    return (
        <div style={props.style} className="button_container">
            <div
                onClick={!props.disabled?props.clickEvent:undefined}
                className={`button noselect ${props.type} ${props.disabled?"btn_disabled":""}`}
                style={props.buttonStyle}
            >
                {props.label}
            </div>
        </div>
    );
}
